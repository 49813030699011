<template>
  <div>
    <!-- 卡片视图区域 -->
    <el-card>
      <!-- 第一行，输入框和添加按钮 -->
      <el-row>
        <!-- 添加按钮 -->
        <el-col :span="5">
          <el-button
            type="primary"
            @click="showAddMerchantCategoryDialog"
          >+ 新增业态</el-button>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="6">
          <!-- 输入框，尾部带按钮 -->
          <div class="input">
            业态名称：
            <el-input
              placeholder="请输入业态名称"
              v-model="queryInfo.categoryName"
              clearable
              @clear="getMerchantCategoryList"
              @change="searchMerchantCategory"
            >
              <el-button
                slot="append"
                type="primary"
                @click="searchMerchantCategory"
              >搜索</el-button>
            </el-input>
          </div>
        </el-col>
      </el-row>
      <!-- 内容主体 -->
      <el-table
        :data="merchantCategoryList"
        stripe
        border
      >
        <!-- 第一列id -->
        <el-table-column
          label="id"
          width="100"
          aligin="center"
          prop="id"
        >
        </el-table-column>
        <!-- 第二列业态名称 -->
        <el-table-column
          label="业态名称"
          aligin="center"
          prop="categoryName"
        >
        </el-table-column>
        <!-- 第三列状态 -->
        <el-table-column
          label="业态图片"
          aligin="center"
        >
          <template slot-scope="scope">
            <el-popover
              trigger="hover"
              title=""
              placement="bottom"
              v-if="scope.row.icon"
            >
              <img
                :src="scope.row.icon"
                width="200"
                height="200"
              />
              <img
                slot="reference"
                :src="scope.row.icon"
                style="height: 80px;width: 80px"
              />
            </el-popover>
          </template>
        </el-table-column>
        <!-- 第四列查看所属商品分类 -->
        <!-- <el-table-column label="所属商品分类" width="200" aligin="center">
          <template slot-scope="scope">
            <el-button
              type="primary"
              size="mini"
              @click="goGoodsCate(scope.row.id)"
              >商品分类</el-button
            >
          </template>
        </el-table-column> -->
        <!-- 第六列操作 -->
        <el-table-column
          label="操作"
          width="300"
          aligin="center"
        >
          <template slot-scope="scope">
            <!-- 编辑按钮 -->
            <el-button
              size="mini"
              type="primary"
              @click="showEditMerchantCategoryDialog(scope.row)"
            >修改</el-button>
            <!-- 删除按钮 -->
            <el-button
              size="mini"
              type="danger"
              @click="removeMerchantCategoryById(scope.row.id)"
            >删除</el-button>
            <!-- 商品分类 -->
            <el-button
              type="warning"
              size="mini"
              @click="goGoodsCate(scope.row.id)"
            >商品分类</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryInfo.pageNo"
        :page-sizes="[15, 30, 50, 100]"
        :page-size="queryInfo.limit"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </el-card>
    <!-- 添加摊位业态对话框 -->
    <el-dialog
      :title="title"
      :visible.sync="addMerchantCategoryDialog"
      width="50%"
      @close="clearInfo"
    >
      <el-form
        ref="addMerchantCategoryFormRef"
        :rules="addMerchantCategoryRules"
        :model="merchantCategoryForm"
        label-width="80px"
      >
        <el-form-item
          label="业态名称"
          prop="categoryName"
        >
          <el-input
            v-model="merchantCategoryForm.categoryName"
            clearable
            @input="input"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="业态图标"
          prop="icon"
        >
          <el-upload
            action="api/v1/file/file"
            :data="this.bizType1"
            :before-upload="beforeUpload"
            :on-success="handlesuccess1"
            :show-file-list="false"
          >
            <img
              v-if="merchantCategoryForm.icon"
              :src="merchantCategoryForm.icon"
              class="avatar"
            />
            <i
              v-else
              class="el-icon-plus avatar-uploader-icon"
            ></i>
          </el-upload>
        </el-form-item>
        <el-form-item
          label="排序"
          prop="indexSorted"
        >
          <el-input-number
            v-model="merchantCategoryForm.indexSorted"
            @change="input"
            :min="1"
            :max="100"
          ></el-input-number>
        </el-form-item>
      </el-form>
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="addMerchantCategoryDialog = false">取 消</el-button>
        <el-button
          type="primary"
          @click="submit"
        >确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data () {
    return {
      // 调用业态分类数据的入参
      queryInfo: {
        // 关键词
        categoryName: '',
        limit: 15,
        pageNo: 1
      },
      // 摊位业态数组
      merchantCategoryList: [],
      // 总数
      total: 0,
      // 添加摊位业态对话框,默认关闭
      addMerchantCategoryDialog: false,
      // 添加修改对话框公用，所以定义以下字段
      title: '',
      submitType: '',
      // 添加摊位业态的入参
      merchantCategoryForm: {
        categoryName: '',
        icon: '',
        indexSorted: 1 // 排序
      },
      // 上传业态图标的type
      bizType1: {
        bizType: 'merchantCate'
      },
      // 摊位业态的id
      id: '',
      // 添加表单校验规则
      addMerchantCategoryRules: {
        categoryName: [
          { required: true, message: '请输入业态名称', trigger: 'blur' }
        ],
        icon: [{ required: true, message: '请上传业态图标', trigger: 'blur' }]
      }
    }
  },
  created () {
    // 调用商户摊位业态分类接口
    this.getMerchantCategoryList()
  },
  methods: {
    searchMerchantCategory () {
      this.queryInfo.pageNo = 1
      this.getMerchantCategoryList()
    },
    // 获取商户摊位业态分类
    async getMerchantCategoryList () {
      const { data: res } = await this.$http.get('merchantCategory/list', {
        params: this.queryInfo
      })
      if (res.code !== 0) {
        return this.$message.error('获取商户摊位业态失败')
      }
      // 赋值
      this.merchantCategoryList = res.data.data
      this.total = res.data.total
    },
    // 打开添加业态对话框
    showAddMerchantCategoryDialog () {
      this.title = '添加业态'
      // 定义为添加
      this.submitType = 'add'
      this.addMerchantCategoryDialog = true
    },
    // 确定添加
    async submit () {
      if (this.submitType === 'add') {
        // 校验规则，必填
        this.$refs.addMerchantCategoryFormRef.validate(async valid => {
          if (!valid) return
          const { data: res } = await this.$http.post(
            'merchantCategory',
            this.merchantCategoryForm
          )
          if (res.code !== 0) {
            return this.$message.error(res.message)
          }
          this.$message.success('添加业态成功')
          this.getMerchantCategoryList()
          this.merchantCategoryForm = {}
          this.addMerchantCategoryDialog = false
        })
      } else {
        // 校验规则，必填
        this.$refs.addMerchantCategoryFormRef.validate(async valid => {
          if (!valid) return
          const { data: res } = await this.$http.put(
            `merchantCategory/${this.id}`,
            this.merchantCategoryForm
          )
          if (res.code !== 0) {
            return this.$message.error(res.message)
          }
          this.$message.success('修改业态成功')
          this.getMerchantCategoryList()
          this.merchantCategoryForm = {}
          this.addMerchantCategoryDialog = false
        })
      }
    },
    // limit变化，调用列表接口
    handleSizeChange (newSize) {
      this.queryInfo.limit = newSize
      this.getMerchantCategoryList()
    },
    // 页码变化，调用接口
    handleCurrentChange (newPage) {
      this.queryInfo.pageNo = newPage
      this.getMerchantCategoryList()
    },
    // 打开编辑对话框
    showEditMerchantCategoryDialog (row) {
      this.title = '编辑业态'
      this.submitType = 'edit'
      this.id = row.id
      // 赋值名称
      this.merchantCategoryForm.categoryName = row.categoryName
      this.merchantCategoryForm.icon = row.icon
      this.merchantCategoryForm.indexSorted = row.indexSorted
      this.addMerchantCategoryDialog = true
    },
    // 删除业态
    async removeMerchantCategoryById (id) {
      // 弹框提醒
      const confirmResult = await this.$confirm(
        '此操作将永久删除该摊位业态，是否继续？',
        '提示',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }
      ).catch(err => err)
      if (confirmResult !== 'confirm') {
        return this.$message.info('已取消删除')
      }
      // 提交
      const { data: res } = await this.$http.delete(`merchantCategory/${id}`)
      if (res.code !== 0) {
        return this.$message.error('删除摊位业态失败')
      }
      this.$message.success('删除摊位业态成功')
      this.getMerchantCategoryList()
    },
    // 跳转到商品分类页面
    goGoodsCate (id) {
      this.$router.push({ path: '/productCate', query: { id: id } })
      window.sessionStorage.setItem('merchantCategoryId', id)
    },
    // 关闭对话框，清空入参
    clearInfo () {
      this.merchantCategoryForm = {}
      this.merchantCategoryForm.indexSorted = 1
    },
    beforeUpload (file) {
      const types = ['image/jpeg', 'image/jpg', 'image/png', 'image/bmp']
      const isImage = types.includes(file.type)
      if (!isImage) {
        this.$message.error('上传图片只能是jpg、jpeg、bmp、png格式')
        return false
      }
      return true
    },
    // 监听图标上传成功的钩子
    handlesuccess1 (response, file, fileList) {
      console.log(response)
      this.$forceUpdate()
      this.merchantCategoryForm.icon = response.data
    },
    input () {
      this.$forceUpdate()
    }
  }
}
</script>

<style lang="less" scoped>
.el-row {
  margin-bottom: 20px;
}
.el-col {
  margin-right: 20px;
}
.input {
  display: flex;
  width: 340px;
  align-items: center;
  justify-content: center;
}
.el-input {
  width: 250px;
}

.avatar {
  width: 100px;
  height: 100px;
}
</style>
